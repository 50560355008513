import clubs from '../data/clubs.json'
import countries from '../data/countries.json'
import years from '../data/years.json'
import achievements from '../data/achievements.json'
import dts from '../data/dts.json'
import positions from '../data/positions.json'

function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
}

function isValidResult(result) {
    let highRatedClubsCount = 0;
    let countriesCount = 0;
    let yearsCount = 0;
    let positionsCount = 0;
    let balonOrIndex = -1;
    let campeonMundoIndex = -1;
    let borussiaDortmundIndex = -1;
    let napoliIndex = -1;
    let spainIndex = -1;
    let atalantaIndex = -1;
    let UruguayIndex = -1;
    let manchesterCityIndex = -1;
    let valenciaIndex = -1;
    let InglateraIndex = -1;
    let bayernMunichIndex = -1;
    let evertonIndex = -1;
    let bocaIndex = -1;
    let villarrealIndex = -1;
    let BelgicaIndex = -1;
    let westHamIndex = -1;
    let celticIndex = -1;
    let GuardiolaIndex = -1;
    let africanPlayerIndex = -1;
    let riverIndex = -1;
    let brightonIndex = -1;
    let CroaciaIndex = -1;
    let leedsIndex = -1;
    let manchesterUnitedIndex = -1;
    let psvIndex = -1;
    let astonVillaIndex = -1;
    let realMadridIndex = -1;
    let udineseIndex = -1;
    let ColombiaIndex = -1;
    let leicesterIndex = -1;
    let juventusIndex = -1;
    let barcelonaIndex = -1;
    let nottinghamForestIndex = -1;
    let interIndex = -1;
    let lazioIndex = -1;
    let olympiqueLyonIndex = -1;
    let AlemaniaIndex = -1;
    let portoIndex = -1;
    let feyenoordIndex = -1;
    let atleticoMadridIndex = -1;
    let southamptonIndex = -1;
    let psgIndex = -1;
    let SaudiLeagueIndex = -1;
    let index25 = -1;
    let ajaxIndex = -1;
    let milanIndex = -1;
    let concacafIndex = -1;
    let sevillaIndex = -1;
    let romanIndex = -1;
    let ItalianIndex = -1;
    let AncelottiIndex = -1;
    let newcastleIndex = -1;
    let eurocopaIndex = -1;
    let copaAmericaIndex = -1;
    let jjooIndex = -1;
    let botaOroIndex = -1;
    let transferValueIndex60 = -1;
    let goldenboyIndex = -1;

    for (let i = 0; i < result.length; i++) {
        if (clubs[result[i]] >= 4) highRatedClubsCount++;
        if (countries.hasOwnProperty(result[i])) countriesCount++;
        if (years.hasOwnProperty(result[i])) yearsCount++;
        if (result[i] === "balon_oro") balonOrIndex = i;
        if (result[i] === "campeon_mundo") campeonMundoIndex = i;
        if (result[i] === "borussia_dortmund") borussiaDortmundIndex = i;
        if (result[i] === "napoli") napoliIndex = i;
        if (result[i] === "España") spainIndex = i;
        if (result[i] === "atalanta") atalantaIndex = i;
        if (result[i] === "Uruguay") UruguayIndex = i;
        if (result[i] === "manchester_city") manchesterCityIndex = i;
        if (result[i] === "valencia") valenciaIndex = i;
        if (result[i] === "Inglaterra") InglateraIndex = i;
        if (result[i] === "bayern_munich") bayernMunichIndex = i;
        if (result[i] === "everton") evertonIndex = i;
        if (result[i] === "boca-juniors") bocaIndex = i;
        if (result[i] === "villarreal") villarrealIndex = i;
        if (result[i] === "Belgica") BelgicaIndex = i;
        if (result[i] === "west_ham_united") westHamIndex = i;
        if (result[i] === "celtic") celticIndex = i;
        if (result[i] === "Guardiola") GuardiolaIndex = i;
        if (result[i] === "river-plate") riverIndex = i;
        if (result[i] === "brighton_and_hove_albion") brightonIndex = i;
        if (result[i] === "Croacia") CroaciaIndex = i;
        if (result[i] === "leeds_united") leedsIndex = i;
        if (result[i] === "manchester_united") manchesterUnitedIndex = i;
        if (result[i] === "psv") psvIndex = i;
        if (result[i] === "aston_villa") astonVillaIndex = i;
        if (result[i] === "real_madrid") realMadridIndex = i;
        if (result[i] === "udinese") udineseIndex = i;
        if (result[i] === "Colombia") ColombiaIndex = i;
        if (result[i] === "leicester") leicesterIndex = i;
        if (result[i] === "juventus") juventusIndex = i;
        if (result[i] === "barcelona") barcelonaIndex = i;
        if (result[i] === "nottingham_forest") nottinghamForestIndex = i;
        if (result[i] === "inter") interIndex = i;
        if (result[i] === "lazio") lazioIndex = i;
        if (result[i] === "olympique_lyon") olympiqueLyonIndex = i;
        if (result[i] === "Alemania") AlemaniaIndex = i;
        if (result[i] === "porto") portoIndex = i;
        if (result[i] === "feyenoord") feyenoordIndex = i;
        if (result[i] === "atletico_madrid") atleticoMadridIndex = i;
        if (result[i] === "southampton") southamptonIndex = i;
        if (result[i] === "african_player") africanPlayerIndex = i;
        if (result[i] === "psg") psgIndex = i;
        if (result[i] === "Saudi_League") SaudiLeagueIndex = i;
        if (result[i] === "25") index25 = i;
        if (result[i] === "ajax") ajaxIndex = i;
        if (result[i] === "milan") milanIndex = i;
        if (result[i] === "concacaf_player") concacafIndex = i;
        if (result[i] === "sevilla") sevillaIndex = i;
        if (result[i] === "roma") romanIndex = i;
        if (result[i] === "Italia") ItalianIndex = i;
        if (result[i] === "Ancelotti") AncelottiIndex = i;
        if (result[i] === "newcastle") newcastleIndex = i;
        if (result[i] === "eurocopa") eurocopaIndex = i;
        if (result[i] === "copa_america") copaAmericaIndex = i;
        if (result[i] === "jjoo") jjooIndex = i;
        if (result[i] === "bota_oro") botaOroIndex = i;
        if (result[i] === "60transferValue") transferValueIndex60 = i;
        if (result[i] === "goldenboy") goldenboyIndex = i
    }

    if (highRatedClubsCount < 3) return false;
    if (yearsCount > 1) return false;

    const argentinianClubs = ["river-plate", "boca-juniors", "independiente", "racing"];
    const allowedCountriesForArgentinianClubs = ["Argentina", "Colombia", "Uruguay"];
    const notAllowedCountriesForCampeonMundo = ["Portugal", "Paises Bajos", "Colombia", "Croacia", "Belgica", "african_player", "concacaf_player"];
    const allowedCountriesForEurocopa = ["España", "Italia", "Alemania", "Francia", "Portugal", "Paises Bajos", "Dinamarca"];
    const notAllowedForEurocopa = ["boca-juniors", "copa_america", "african_player", "concacaf_player"];
    const allowedCountriesForCopaAmerica = ["Argentina", "Brasil", "Uruguay", "Colombia"];
    const notAllowedForCopaAmerica = ["eurocopa", "african_player", "concacaf_player", "celtic", "feyenoord", "leeds_united", "leicester", "newcastle"];
    const notAllowedForJjoo = ["brighton_and_hove_albion", "feyenoord", "fiorentina", "leeds_united", "leicester", "nottingham_forest", "Colombia", "Croacia", "Paises Bajos", "Portugal" ];
    const notAllowedForBotaOro = ["atalanta", "boca-juniors", "chelsea", "everton", "napoli", "olympique_lyon", "nottingham_forest", "Colombia", "river-plate", "valencia", "brighton_and_hove_albion", "udinese", "west_ham_united", "España", "african_player", "Defender", "Midfielder", "Goalkeeper"];
    const notAllowedForGoldenBoy = ["aston_villa", "atalanta", "boca-juniors", "brighton_and_hove_albion", "celtic", "feyenoord", "galatasaray", "leeds_united", "leicester", "lazio", "nottingham_forest", "sporting_cp", "napoli", "river-plate", "newcastle", "southampton", "udinese", "Saudi_League", "west_ham_united", "Colombia", "Belgica", "Croacia", "Uruguay", "african_player", "concacaf_player", "Goalkeeper"];

    let argentinianClubInFirstHalf = false;
    let argentinianClubInSecondHalf = false;

    for (let i = 0; i < result.length; i++) {
        if (positions.hasOwnProperty(result[i])) positionsCount++;
    }

    if (positionsCount > 1) return false;

    for (let i = 0; i < 3; i++) {
        if (argentinianClubs.includes(result[i])) {
            argentinianClubInFirstHalf = true;
            break;
        }
    }

    for (let i = 3; i < result.length; i++) {
        if (argentinianClubs.includes(result[i])) {
            argentinianClubInSecondHalf = true;
            break;
        }
    }

    if (argentinianClubInFirstHalf) {
        for (let i = 3; i < result.length; i++) {
            if (countries.hasOwnProperty(result[i]) && !allowedCountriesForArgentinianClubs.includes(result[i])) return false;
        }
    }

    if (argentinianClubInSecondHalf) {
        for (let i = 0; i < 3; i++) {
            if (countries.hasOwnProperty(result[i]) && !allowedCountriesForArgentinianClubs.includes(result[i])) return false;
        }
    }

    if (campeonMundoIndex >= 0) {
        if (campeonMundoIndex < 3) {
            for (let i = 3; i < result.length; i++) {
                if (notAllowedCountriesForCampeonMundo.includes(result[i])) return false;
            }
        } else {
            for (let i = 0; i < 3; i++) {
                if (notAllowedCountriesForCampeonMundo.includes(result[i])) return false;
            }
        }
    }

    if (eurocopaIndex >= 0) {
        if (eurocopaIndex < 3) {
            for (let i = 3; i < result.length; i++) {
                if (notAllowedForEurocopa.includes(result[i])) return false;
                if (countries.hasOwnProperty(result[i]) && !allowedCountriesForEurocopa.includes(result[i])) return false;
            }
        } else {
            for (let i = 0; i < 3; i++) {
                if (notAllowedForEurocopa.includes(result[i])) return false;
                if (countries.hasOwnProperty(result[i]) && !allowedCountriesForEurocopa.includes(result[i])) return false;
            }
        }
    }

    if (copaAmericaIndex >= 0) {
        if (copaAmericaIndex < 3) {
            for (let i = 3; i < result.length; i++) {
                if (notAllowedForCopaAmerica.includes(result[i])) return false;
                if (countries.hasOwnProperty(result[i]) && !allowedCountriesForCopaAmerica.includes(result[i])) return false;
            }
        } else {
            for (let i = 0; i < 3; i++) {
                if (notAllowedForCopaAmerica.includes(result[i])) return false;
                if (countries.hasOwnProperty(result[i]) && !allowedCountriesForCopaAmerica.includes(result[i])) return false;
            }
        }
    }

    if (jjooIndex >= 0) {
        if (jjooIndex < 3) {
            for (let i = 3; i < result.length; i++) {
                if (notAllowedForJjoo.includes(result[i])) return false;
            }
        } else {
            for (let i = 0; i < 3; i++) {
                if (notAllowedForJjoo.includes(result[i])) return false;
            }
        }
    }

    if (botaOroIndex >= 0) {
        if (botaOroIndex < 3) {
            for (let i = 3; i < result.length; i++) {
                if (notAllowedForBotaOro.includes(result[i])) return false;
            }
        } else {
            for (let i = 0; i < 3; i++) {
                if (notAllowedForBotaOro.includes(result[i])) return false;
            }
        }
    }

    if (goldenboyIndex >= 0) {
        if (goldenboyIndex < 3) {
            for (let i = 3; i < result.length; i++) {
                if (notAllowedForGoldenBoy.includes(result[i])) return false;
            }
        } else {
            for (let i = 0; i < 3; i++) {
                if (notAllowedForGoldenBoy.includes(result[i])) return false;
            }
        }
    }

    if (balonOrIndex >= 0) {
        if (balonOrIndex < 3) {
            for (let i = 3; i < result.length; i++) {
                if (result[i] === "Uruguay" || result[i] === "Belgica" || clubs[result[i]] < 4 || result[i] === '25' || result[i] === 'Colombia' || result[i] === "valencia" || result[i] === "arsenal" || result[i] === "borussia_dortmund" || result[i] === "sevilla" || result[i] === "atletico_madrid" || result[i] === "boca-juniors" || result[i] === "porto" || result[i] === "nottingham_forest" || result[i] === "roma" || result[i] === "valencia" || result[i] === "udinese" || result[i] === "valencia" || result[i] === "villarreal" || result[i] === "west_ham_united" || result[i] === "aston_villa" || result[i] === "atalanta" || result[i] === "brighton_and_hove_albion" || result[i] === "celtic" || result[i] === "everton" || result[i] === "galatasaray" || result[i] === "leeds_united" || result[i] === "leicester" || result[i] === "concacaf_player") return false;
            }
        } else {
            for (let i = 0; i < 3; i++) {
                if (result[i] === "Uruguay" || result[i] === "Belgica" || clubs[result[i]] < 4 || result[i] === '25' || result[i] === 'Colombia' || result[i] === "valencia" || result[i] === "arsenal" || result[i] === "borussia_dortmund" || result[i] === "sevilla" || result[i] === "atletico_madrid" || result[i] === "boca-juniors" || result[i] === "porto" || result[i] === "nottingham_forest" || result[i] === "roma" || result[i] === "valencia" || result[i] === "udinese" || result[i] === "valencia" || result[i] === "villarreal" || result[i] === "west_ham_united" || result[i] === "aston_villa" || result[i] === "atalanta" || result[i] === "brighton_and_hove_albion" || result[i] === "celtic" || result[i] === "everton" || result[i] === "galatasaray" || result[i] === "leeds_united" || result[i] === "leicester" || result[i] === "concacaf_player") return false;
            }
        }
    }

    if (countriesCount > 0) {
        let firstHalfCountryCount = 0;
        let secondHalfCountryCount = 0;
        for (let i = 0; i < 3; i++) {
            if (countries.hasOwnProperty(result[i])) firstHalfCountryCount++;
            if (result[i] === "african_player") firstHalfCountryCount++;
            if (result[i] === "concacaf_player") firstHalfCountryCount++;
        }
        for (let i = 3; i < result.length; i++) {
            if (countries.hasOwnProperty(result[i])) secondHalfCountryCount++;
            if (result[i] === "african_player") secondHalfCountryCount++;
            if (result[i] === "concacaf_player") secondHalfCountryCount++;
        }
        if (firstHalfCountryCount > 0 && secondHalfCountryCount > 0) return false;
    }

    if (borussiaDortmundIndex < 3 && napoliIndex >= 3) return false;
    if (napoliIndex < 3 && borussiaDortmundIndex >= 3) return false;
    if (spainIndex < 3 && atalantaIndex >= 3) return false;
    if (atalantaIndex < 3 && spainIndex >= 3) return false;
    if (UruguayIndex < 3 && manchesterCityIndex >= 3) return false;
    if (manchesterCityIndex < 3 && UruguayIndex >= 3) return false;
    if (manchesterCityIndex < 3 && napoliIndex >= 3) return false;
    if (napoliIndex < 3 && manchesterCityIndex >= 3) return false;
    if (valenciaIndex <3 && InglateraIndex >= 3) return false;
    if (InglateraIndex <3 && valenciaIndex >= 3) return false;
    if (bayernMunichIndex <3 && UruguayIndex >= 3) return false;
    if (UruguayIndex <3 && bayernMunichIndex >= 3) return false;
    if (evertonIndex <3 && bocaIndex >= 3) return false;
    if (bocaIndex <3 && evertonIndex >= 3) return false;
    if (bocaIndex <3 && celticIndex >= 3) return false;
    if (celticIndex <3 && bocaIndex >= 3) return false;
    if (villarrealIndex <3 && BelgicaIndex >= 3) return false;
    if (westHamIndex <3 && BelgicaIndex >= 3) return false;
    if (BelgicaIndex <3 && villarrealIndex >= 3) return false;
    if (BelgicaIndex <3 && westHamIndex >= 3) return false;
    if (atalantaIndex <3 && GuardiolaIndex >= 3) return false;
    if (GuardiolaIndex <3 && atalantaIndex >= 3) return false;
    if (celticIndex <3 && riverIndex >= 3) return false;
    if (riverIndex <3 && celticIndex >= 3) return false;
    if (brightonIndex <3 && CroaciaIndex >= 3) return false;
    if (CroaciaIndex <3 && brightonIndex >= 3) return false;
    if (leedsIndex <3 && CroaciaIndex >= 3) return false;
    if (CroaciaIndex <3 && leedsIndex >= 3) return false;
    if (manchesterUnitedIndex <3 && CroaciaIndex >= 3) return false;
    if (CroaciaIndex <3 && manchesterUnitedIndex >= 3) return false;
    if (psvIndex <3 && CroaciaIndex >= 3) return false;
    if (CroaciaIndex <3 && psvIndex >= 3) return false;
    if (villarrealIndex <3 && CroaciaIndex >= 3) return false;
    if (CroaciaIndex <3 && villarrealIndex >= 3) return false;
    if (astonVillaIndex <3 && realMadridIndex >= 3) return false;
    if (realMadridIndex <3 && astonVillaIndex >= 3) return false;
    if (astonVillaIndex <3 && udineseIndex >= 3) return false;
    if (udineseIndex <3 && astonVillaIndex >= 3) return false;
    if (GuardiolaIndex <3 && ColombiaIndex >= 3) return false;
    if (ColombiaIndex <3 && GuardiolaIndex >= 3) return false;
    if (leicesterIndex <3 && juventusIndex >= 3) return false;
    if (juventusIndex <3 && leicesterIndex >= 3) return false;
    if (nottinghamForestIndex <3 && barcelonaIndex >= 3) return false;
    if (barcelonaIndex <3 && nottinghamForestIndex >= 3) return false;
    if (nottinghamForestIndex <3 && bocaIndex >= 3) return false;
    if (bocaIndex <3 && nottinghamForestIndex >= 3) return false;
    if (nottinghamForestIndex <3 && borussiaDortmundIndex >= 3) return false;
    if (borussiaDortmundIndex <3 && nottinghamForestIndex >= 3) return false;
    if (nottinghamForestIndex <3 && interIndex >= 3) return false;
    if (interIndex <3 && nottinghamForestIndex >= 3) return false;
    if (lazioIndex <3 && nottinghamForestIndex >= 3) return false;
    if (nottinghamForestIndex <3 && lazioIndex >= 3) return false;
    if (nottinghamForestIndex <3 && olympiqueLyonIndex >= 3) return false;
    if (olympiqueLyonIndex <3 && nottinghamForestIndex >= 3) return false;
    if (nottinghamForestIndex <3 && riverIndex >= 3) return false;
    if (riverIndex <3 && nottinghamForestIndex >= 3) return false;
    if (nottinghamForestIndex <3 && UruguayIndex >= 3) return false;
    if (UruguayIndex <3 && nottinghamForestIndex >= 3) return false;
    if (UruguayIndex <3 && astonVillaIndex >= 3) return false;
    if (astonVillaIndex <3 && UruguayIndex >= 3) return false;
    if (UruguayIndex <3 && brightonIndex >= 3) return false;
    if (brightonIndex <3 && UruguayIndex >= 3) return false;
    if (UruguayIndex <3 && feyenoordIndex >= 3) return false;
    if (feyenoordIndex <3 && UruguayIndex >= 3) return false;
    if (UruguayIndex <3 && leedsIndex >= 3) return false;
    if (leedsIndex <3 && UruguayIndex >= 3) return false;
    if (UruguayIndex <3 && leicesterIndex >= 3) return false;
    if (leicesterIndex <3 && UruguayIndex >= 3) return false;
    if (UruguayIndex <3 && olympiqueLyonIndex >= 3) return false;
    if (olympiqueLyonIndex <3 && UruguayIndex >= 3) return false;
    if (nottinghamForestIndex <3 && ColombiaIndex >= 3) return false;
    if (ColombiaIndex <3 && nottinghamForestIndex >= 3) return false;
    if (celticIndex <3 && ColombiaIndex >= 3) return false;
    if (ColombiaIndex <3 && celticIndex >= 3) return false;
    if (AlemaniaIndex <3 && portoIndex >= 3) return false;
    if (portoIndex <3 && AlemaniaIndex >= 3) return false;
    if (AlemaniaIndex <3 && villarrealIndex >= 3) return false;
    if (villarrealIndex <3 && AlemaniaIndex >= 3) return false;
    if (atleticoMadridIndex <3 && feyenoordIndex >= 3) return false;
    if (feyenoordIndex <3 && atleticoMadridIndex >= 3) return false;
    if (atalantaIndex <3 && feyenoordIndex >= 3) return false;
    if (feyenoordIndex <3 && atalantaIndex >= 3) return false;
    if (udineseIndex <3 && southamptonIndex >= 3) return false;
    if (southamptonIndex <3 && udineseIndex >= 3) return false;
    if (southamptonIndex <3 && riverIndex >= 3) return false;
    if (riverIndex <3 && southamptonIndex >= 3) return false;
    if (southamptonIndex <3 && psvIndex >= 3) return false;
    if (psvIndex <3 && southamptonIndex >= 3) return false;
    if (southamptonIndex <3 && borussiaDortmundIndex >= 3) return false;
    if (borussiaDortmundIndex <3 && southamptonIndex >= 3) return false;
    if (leedsIndex <3 && atalantaIndex >= 3) return false;
    if (atalantaIndex <3 && leedsIndex >= 3) return false;
    if (leedsIndex <3 && borussiaDortmundIndex >= 3) return false;
    if (borussiaDortmundIndex <3 && leedsIndex >= 3) return false;
    if (leedsIndex <3 && bocaIndex >= 3) return false;
    if (bocaIndex <3 && leedsIndex >= 3) return false;
    if (leedsIndex <3 && napoliIndex >= 3) return false;
    if (napoliIndex <3 && leedsIndex >= 3) return false;
    if (leedsIndex <3 && olympiqueLyonIndex >= 3) return false;
    if (olympiqueLyonIndex <3 && leedsIndex >= 3) return false;
    if (leedsIndex <3 && portoIndex >= 3) return false;
    if (portoIndex <3 && leedsIndex >= 3) return false;
    if (africanPlayerIndex <3 && riverIndex >= 3) return false;
    if (riverIndex <3 && africanPlayerIndex >= 3) return false;
    if (leicesterIndex <3 && UruguayIndex >= 3) return false;
    if (UruguayIndex <3 && leicesterIndex >= 3) return false;
    if (brightonIndex <3 && psgIndex >= 3) return false;
    if (psgIndex <3 && brightonIndex >= 3) return false;
    if (valenciaIndex <3 && leicesterIndex >= 3) return false;
    if (leicesterIndex <3 && valenciaIndex >= 3) return false;
    if (leicesterIndex <3 && ColombiaIndex >= 3) return false;
    if (ColombiaIndex <3 && leicesterIndex >= 3) return false;
    if (SaudiLeagueIndex <3 && index25 >= 3) return false;
    if (index25 <3 && SaudiLeagueIndex >= 3) return false;
    if (SaudiLeagueIndex <3 && atalantaIndex >= 3) return false;
    if (atalantaIndex <3 && SaudiLeagueIndex >= 3) return false;
    if (SaudiLeagueIndex <3 && borussiaDortmundIndex >= 3) return false;
    if (borussiaDortmundIndex <3 && SaudiLeagueIndex >= 3) return false;
    if (SaudiLeagueIndex <3 && southamptonIndex >= 3) return false;
    if (southamptonIndex <3 && SaudiLeagueIndex >= 3) return false;
    if (SaudiLeagueIndex <3 && westHamIndex >= 3) return false;
    if (westHamIndex <3 && SaudiLeagueIndex >= 3) return false;
    if (ajaxIndex <3 && leicesterIndex >= 3) return false;
    if (leicesterIndex <3 && ajaxIndex >= 3) return false;
    if (leicesterIndex <3 && juventusIndex >= 3) return false;
    if (juventusIndex <3 && leicesterIndex >= 3) return false;
    if (milanIndex <3 && SaudiLeagueIndex >= 3) return false;
    if (SaudiLeagueIndex <3 && milanIndex >= 3) return false;
    if (concacafIndex <3 && bocaIndex >= 3) return false;
    if (bocaIndex <3 && concacafIndex >= 3) return false;
    if (sevillaIndex <3 && brightonIndex >= 3) return false;
    if (brightonIndex <3 && sevillaIndex >= 3) return false;
    if (romanIndex <3 && brightonIndex >= 3) return false;
    if (brightonIndex <3 && romanIndex >= 3) return false;
    if (ItalianIndex <3 && GuardiolaIndex >= 3) return false;
    if (GuardiolaIndex <3 && ItalianIndex >= 3) return false;
    if (campeonMundoIndex <3 && feyenoordIndex >= 3) return false;
    if (feyenoordIndex <3 && campeonMundoIndex >= 3) return false;
    if (AncelottiIndex <3 && brightonIndex >= 3) return false;
    if (brightonIndex <3 && AncelottiIndex >= 3) return false;
    if (newcastleIndex <3 && GuardiolaIndex >= 3) return false;
    if (GuardiolaIndex <3 && newcastleIndex >= 3) return false;
    if (transferValueIndex60 <3 && feyenoordIndex >= 3) return false;
    if (feyenoordIndex <3 && transferValueIndex60 >= 3) return false;
    if (transferValueIndex60 <3 && leedsIndex >= 3) return false;
    if (leedsIndex <3 && transferValueIndex60 >= 3) return false;
    if (transferValueIndex60 <3 && nottinghamForestIndex >= 3) return false;
    if (nottinghamForestIndex <3 && transferValueIndex60 >= 3) return false;
    if (transferValueIndex60 <3 && psvIndex >= 3) return false;
    if (psvIndex <3 && transferValueIndex60 >= 3) return false;

    return true;
}


function getRandomItemByCategory() {
    const clubItems = Object.keys(clubs);
    const countryItems = Object.keys(countries);
    const yearItems = Object.keys(years);
    const achievementItems = Object.keys(achievements);
    const dtItems = Object.keys(dts);
    const positionItems = Object.keys(positions);

    const clubWeight = 0.6;
    const countryWeight = 1.0;
    const yearWeight = 0.6;
    const achievementWeight = 1.0;
    const dtWeight = 1.0;
    const positionWeight = 0.5;

    const totalWeight = clubItems.length * clubWeight +
        countryItems.length * countryWeight +
        yearItems.length * yearWeight +
        achievementItems.length * achievementWeight +
        dtItems.length * dtWeight +
        positionItems.length * positionWeight;

    const randomValue = Math.random() * totalWeight;
    let accumulatedWeight = 0;

    for (let item of clubItems) {
        accumulatedWeight += clubWeight;
        if (randomValue < accumulatedWeight) {
            return item;
        }
    }

    for (let item of countryItems) {
        accumulatedWeight += countryWeight;
        if (randomValue < accumulatedWeight) {
            return item;
        }
    }

    for (let item of yearItems) {
        accumulatedWeight += yearWeight;
        if (randomValue < accumulatedWeight) {
            return item;
        }
    }

    for (let item of achievementItems) {
        accumulatedWeight += achievementWeight;
        if (randomValue < accumulatedWeight) {
            return item;
        }
    }

    for (let item of dtItems) {
        accumulatedWeight += dtWeight;
        if (randomValue < accumulatedWeight) {
            return item;
        }
    }

    for (let item of positionItems) {
        accumulatedWeight += positionWeight;
        if (randomValue < accumulatedWeight) {
            return item;
        }
    }

    throw new Error("No se pudo seleccionar un elemento aleatorio.");
}

export function generateCustomArray() {
    let result;
    do {
        const selectedItems = new Set();

        while (selectedItems.size < 6) {
            const randomItem = getRandomItemByCategory();
            selectedItems.add(randomItem);
        }

        result = Array.from(selectedItems);
        shuffleArray(result);
    } while (!isValidResult(result));

    return result;
}